<template>
  <van-config-provider :theme-vars="themeVars">
    <router-view />
  </van-config-provider>
</template>

<script setup>
import {
  ref,
  nextTick,
  onMounted,
  reactive,
  watch,
  computed,
  toRaw,
  getCurrentInstance,
} from "vue";
import device from "current-device";

import { useStore } from "vuex";
const store = useStore();

const themeVars = reactive({
  // swipeIndicatorMargin: "100px",
  // swipeIndicatorSize: "100px",
});

const screenWidth = ref(0);
const screenHeight = ref(0);
const shortLength = ref(0);

// 获取屏幕宽高
const getScreenSize = () => {
  const htmlDom = document.querySelector("html");

  screenWidth.value = htmlDom.clientWidth;
  screenHeight.value = htmlDom.clientHeight;
  shortLength.value =
    screenWidth.value < screenHeight.value
      ? screenWidth.value
      : screenHeight.value; // 获取短边长度

  window.sessionStorage.setItem("screenWidth", screenWidth.value);
  window.sessionStorage.setItem("screenHeight", screenHeight.value);

  setRootFontSize(htmlDom); // 设置 html 根字体字号
  isIpadHorizontalFunc(); // 首次加载判断是否为 iPad 横屏
  watchOrientation(); // 监听屏幕旋转，实时判断是否为 pad 横屏
};

// 设置 html 跟字体字号
const setRootFontSize = (htmlDom) => {
  htmlDom.style.fontSize = (shortLength.value / 390) * 10 + "px"; // 根据屏幕短边宽度动态设置html字体大小
};

// 通过 device.js 插件判断是否为 iPad 横屏，如果是平板，记录旋转方向到 vue-x
const isIpadHorizontalFunc = () => {
  if (device.tablet()) {
    store.dispatch("setIsPadHorizontal", device.landscape() ? true : false);
    // console.log(store.state.isPadHorizontal);
  } else {
    store.dispatch("setIsPadHorizontal", false);
  }
};

// 监听屏幕旋转，实时判断是否为 pad 横屏
const watchOrientation = () => {
  window.addEventListener("orientationchange", () => {
    isIpadHorizontalFunc();
  });
};

/*禁止缩放safari浏览器*/
const disabledSafari = () => {
  /* 阻止双击放大*/
  var lastTouchEnd = 0;
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
  /* 阻止双指指掐放大*/
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });

  // document.addEventListener("touchend", function (event) {
  //   event.preventDefault();
  // });
};

onMounted(() => {
  getScreenSize();
  // disabledSafari();
});
</script>

<style lang="scss">
@import "@/style/constants.scss";
// 引入 css 不能直接用 @ 语法糖，绝对路径前需要加 ~；或者使用相对路径
// https://blog.csdn.net/u014582342/article/details/126453238
@import "~@/assets/fonts/font.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 0.01rem;
  font-family: sourcehansans_regular;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  line-height: 1.3;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-size: 62.5%; // 以10px为基准。1rem == 10px
  background-color: $bg-color;
}

#app {
  // overflow: hidden;
  width: 100%;
  // height: 100%;
}

/**
  * 全局滚动条透明
  */
::-webkit-scrollbar {
  width: 0px; /* 竖向滚动条宽度 */
  height: 0px; /* 横向滚动条高度 */
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /* 滚动条样式 */
  background-color: transparent; /* 滚动条颜色 */
}
::-webkit-scrollbar-thumb:hover {
  background-color: transparent; /* 滚动条悬浮颜色 */
  width: 0px; /* 竖向滚动条宽度 */
  height: 0px; /* 横向滚动条高度 */
  opacity: 0;
}
::-webkit-scrollbar-track-piece {
  background: transparent; /* 滚动条背景颜色 */
}
* {
  scrollbar-color: transparent transparent; /* 滑块颜色  滚动条背景颜色 未生效 */
  scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
