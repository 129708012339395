import { createStore } from "vuex";

export default createStore({
  state: {
    isPadHorizontal: false,
  },
  mutations: {
    SetIsPadHorizontal(state, value) {
      state.isPadHorizontal = value;
    },
  },
  actions: {
    setIsPadHorizontal(context, value) {
      context.commit("SetIsPadHorizontal", value);
    },
  },
  modules: {},
});
