import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/http/index.js";

import { Swipe, SwipeItem, ConfigProvider, Notify } from "vant";
import "vant/lib/index.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Swipe);
app.use(SwipeItem);
app.use(Notify);
app.use(ConfigProvider);
app.mount("#app");
