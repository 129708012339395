import { createRouter, createWebHashHistory } from "vue-router";
import Index from "@/views/index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/home.vue"),
      },
      {
        path: "/product-list",
        name: "ProductList",
        component: () => import("@/views/product-list.vue"),
      },
      {
        path: "/product-detail",
        name: "ProductDetail",
        component: () => import("@/views/product-detail.vue"),
      },
    ],
  },
  {
    path: "/prescription",
    name: "Prescription",
    component: () => import("@/views/prescription.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to, from, next) => {
  document.body.scrollTo(0, 0);
});

export default router;
