<template>
  <div
    class="ancvue-index"
    :class="$store.state.isPadHorizontal ? 'pad-horizontal' : 'phone'"
  >
    <!-- header -->
    <div class="ancvue-header">
      <img class="logo" src="@/assets/imgs/logo.png" />
      <img
        v-if="!$store.state.isPadHorizontal"
        ref="headerBgRef"
        class="header-bg"
        src="@/assets/imgs/header-bg.jpg"
      />
      <img
        v-if="$store.state.isPadHorizontal"
        ref="headerBgRef"
        class="header-bg"
        src="@/assets/imgs/header-bg-pad.png"
      />
      <div class="text-wrapper" :style="textWrapperStyle">
        <div class="line-1">安视优散光软镜验配计算器</div>
        <div class="text-2">为您的散光患者简单快速地找到最佳的安视优散光试戴片参数。</div>
        <div class="text-3" @click="howToUse">
          <div class="text-3__left">了解如何使用我们的计算器</div>
          <div class="text-3__right">
            <img src="@/assets/imgs/right-arrow.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- body -->
    <router-view></router-view>
    <!-- declaration -->
    <div class="declaration">
      <span class="text-bold">免责声明:</span>
      <span>
        该计算器结合安视优散光隐形眼镜产品标签仅为教育和方便的目的提供给临床医生。强生视力健公司不参与开具处方，且该计算器不可替代适当的专业教育及培训或独立的专业判断。强生视力健公司不能保证该计算器所提供信息的准确性和完整性。每位患者的临床情况都是独一无二的，对应的诊疗方案也应个性化制订。除了产品标签中所包含的及其他经临床判断为适当的技术外，强生视力健公司不会宣传或推荐任何特定技术。
      </span>
      <span class="text-bold-darker block">各产品系列的重要安全提示</span>
      <span>
        安视优品牌隐形眼镜适用于视力矫正。和其他隐形眼镜一样，部分配戴者可能会感到轻微疼痛、瘙痒或不适。如患者有眼部感染、眼睛不适、过度流泪、视力改变、眼睛发红或其他状况，不应开具隐形眼镜处方。如需了解完整信息可参阅包装说明书，亦可访问
      </span>
      <a class="website" href="https://www.jnjvisionpro.com" target="_blank"
        >www.jnjvisionpro.com</a
      >
      <span>。</span>
    </div>

    <!-- dialog -->
    <div class="dialog" v-show="showHowToUse" @click="showHowToUse = false">
      <div class="dialog_box" @click.stop>
        <div class="scale-wrapper">
          <div class="paragraph-1">
            此处提供的信息将用于安视优散光/球面隐形眼镜产品参数计算。消费者首先进行一项最新的主观验光检查，然后验光师输入最新的单眼或双眼的验光处方及后顶点距离，单/双眼的球镜、柱镜、轴位为必填项，选中"计算"按钮。
          </div>
          <div class="paragraph-1">
            计算结果将根据您输入的处方显示可选用的安视优品牌，选择一个品牌，就会显示出顶点校正后的初始试戴片参数。
          </div>
          <div class="paragraph-1">
            如果在验配过程中观察到试戴片有任何明显镜片片标偏转而影响到视力，选择"镜片片标偏转修正"并输入数值和方向，如有需要，修正后的镜片参数将会被计算出来。请注意：经过修正后的新的镜片参数不影响镜片偏转位置显示。
          </div>
        </div>
        <img src="@/assets/imgs/close_round.png" @click="showHowToUse = false" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const props = defineProps({});
const emits = defineEmits([]);

const headerBgRef = ref();
const headerBgHeight = ref(0);
const showHowToUse = ref(false);

// 点击如何使用弹窗
const howToUse = () => {
  showHowToUse.value = true;
};

// 获取到图片高度后，设置文字div的高度
const textWrapperStyle = computed(() => {
  const style = {};
  style.height = headerBgHeight.value + "px";
  return style;
});

// 获取图片高度
const getHeaderBgHeight = () => {
  const interval = setInterval(() => {
    if (headerBgRef.value && headerBgRef.value.offsetHeight) {
      // 当新的图片高度不等于旧的高度，才进行赋值和停止计时器。否则iPhone有bug，高度更新会晚一拍，横屏时文字高度不变，再竖屏时文字高度却是横屏的图片高度。
      if (headerBgRef.value.offsetHeight != headerBgHeight.value) {
        headerBgHeight.value = headerBgRef.value.offsetHeight;
        clearInterval(interval);
      }
    }
  }, 1);
};

// 监听屏幕旋转，为了适配 pad 横屏的 header 部分
const watchOrientation = () => {
  window.addEventListener("orientationchange", () => {
    getHeaderBgHeight(); // 获取图片高度
  });
};

onMounted(() => {
  getHeaderBgHeight(); // 获取图片高度
  watchOrientation(); // 监听屏幕旋转，主要为了适配 header 的文字垂直居中
});
</script>

<style lang="scss" scoped>
@import "@/style/index/phone.scss";
@import "@/style/index/pad.scss";
</style>
